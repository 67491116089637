// react 
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// ..........

// scss file
import './compliance.scss'
// ..........

// package components
// ..........

// child components
import { useGlobalState } from '../../Statecontext';
// ..........

// icons
import searchIcon from '../../assets/images/search.svg';
import { Button, Input, Pagination, Spin } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableViewIcon from '@mui/icons-material/TableView';
import uploadIcon from '../../assets/images/Vector.svg';
import downloadIcon from '../../assets/images/dowload-icon.svg';
import EastIcon from '@mui/icons-material/East';
import expandIcon from '../../assets/images/expandIcon.svg';
import expandIcon1 from '../../assets/images/expandIcon2.svg';
import plusIcon from '../../assets/images/plus.svg';
import synctheme from '../../assets/images/sync__all.svg';
import syncWhite from '../../assets/images/sync_now.svg';
import { downloadServices } from '../../core/services/download';
import { serviceConfig } from '../../core/services';
// ..........

export const Compliance = () => {

    // form values states
    const [userRole, setUserRole] = useState<any>()
    const [noticeObj, setNoticeObj] = useState(false);
    const [localsearch, setLocalsearch] = useState('')
    // ..........

    // alerts and modal states
    // ..........

    // loading state
    const [loading, setLoading] = useState(false);
    // ..........

    // limit & filter states
    const [filter, setFilter] = useState(false)
    const [filterList, setFilterList] = useState<any>([])
    const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [secFilterCase, setSecFilterCase] = useState('')
    const [checkedArnItems, setCheckedArnItems] = useState<string[]>([])
    const [checkedCaseIdItems, setCheckedCaseIdItems] = useState<string[]>([])
    const [checkedSessionItems, setCheckedSessionItems] = useState<string[]>([])
    const [secondaryFilter, setSecondaryFilter] = useState<any>([])
    const { filterQuery, searchquery, setSearchquery, page, setPage, totalpages, setTotalpages, sumdrawer, downloading, setDownloading, rowsPerPage, setRowsPerPage } = useGlobalState();
    // ..........

    //  datas states
    const [globalSearch, setGlobalSearch] = useState('')
    const [selectedGST, setSelectedGST] = useState<any>([])
    const [selectedRowsArray, setSelectedRowsArray] = useState<any>([])
    const [showsearch, setShowsearch] = useState(false)
    const settings = ['Sort by Due date', 'Sort by Notice date', 'Sort by Tax period'];
    // ..........

    // reference states
    const tableListData = useRef(false)
    const [changeview, setChangeview] = useState(false);
    const [tabview, setTabview] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [complianceData, setComplianceData] = useState<any>([])
    const [sourcedata, setSourcedata] = useState<any>([])
    const navigate = useNavigate()
    // ..........

    const loadComplianceData = useCallback(() => {
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }
        if (searchquery) {
            query['search'] = searchquery;
        }
        setLoading(true)
        serviceConfig.get("compliance_data", true, query, null).then((data: any) => {
            setLoading(false)
            setComplianceData(data.results || data.data)
            setSourcedata(data.results || data.data)
            setTotalpages(data.count)
        }, (err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }, [page, rowsPerPage, filterQuery, searchquery])

    useEffect(() => {
        loadComplianceData()
    }, [loadComplianceData])

    // search 
    const search = (value: any) => {
        if (sourcedata[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = sourcedata.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );
        setComplianceData(filterTable)
    }

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;
        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
        } else {
            setLocalsearch(value)
        }


        // if (userRole === 'Super Admin') {
        //     let query: any = {
        //         trade_name: [value]
        //     };
        //     setFilterQuery(query);
        // } else {
        //     search(value);
        // }

    }
    const searchclick = (value: any) => {
        setPage(1)
        setSearchquery(value)
    }
    // ..........

    // download gst function
    const downloadGST = async (query: any, loading: any) => {
        if (query === 'compliance_list') {
            downloadServices.compliance_list(page, rowsPerPage, JSON.stringify(filterQuery) !== '{}' ? filterQuery : null, loading);
        }
        else
            downloadServices.download_notices(null);
    }
    // ........

    // table action btns
    const btnClick = (data: any, query: any, btnfunction: any) => {

        if (data === 'download' && !downloading) {
            setDownloading(true)
            downloadGST(query, setDownloading);
        }


    }

    // ................

    // button details props
    const buttondetails: any = [
        {
            type: 'download',
            name: 'Download',
            theme: 'theme4',
            conditions: ['if_list'],
            downloadquery: 'compliance_list'
        }
    ]
    // ...........



    return (
        <div className='complaince-overall-container'>
            <div className='complaince-content-container'>
                <div className="z-left-mobile-container">
                    {!showsearch &&
                        <h2 className='z-gst-head'>
                            Compliance Monitor
                        </h2>}
                    {!showsearch && < img
                        src={searchIcon}
                        className='mob-search-icon'
                        style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                        alt='password icon'
                        onClick={() => setShowsearch(true)} />}
                    {showsearch &&
                        <Input
                            className='z-table_global_search'
                            value={searchquery}
                            onChange={handleGlobalSearchChange}
                            placeholder='Type here to search...'
                            suffix={<img
                                onClick={() => setShowsearch(false)}
                                src={searchIcon}
                                style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                alt='password icon' />}
                        />}
                </div>
                <div className={sumdrawer ? "z-left-container-drawer" : "z-left-container"}>
                    <h2 className='z-gst-head'>
                        Compliance Monitor
                    </h2>
                    <Input
                        className='z-table_global_search'
                        value={localsearch}
                        onChange={handleGlobalSearchChange}
                        placeholder='Type here to search...'
                        suffix={<img
                            src={searchIcon}
                            onClick={() => searchclick(localsearch)}
                            style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                            alt='password icon' />}
                    />

                </div>
                <div className="z-right-container">

                    {complianceData.length !== 0 && buttondetails.map((button: any) => {
                        return (
                            <Button
                                id="z-common-gst-solution-button"
                                className={button.type === 'get' ? `${button.theme} get-btn` : button.theme}
                                key={button.name}
                                onClick={(e) => btnClick(button.type, button.downloadquery, button.function)}
                            >
                                {button.type === 'download' && <img className="icon-btn" src={downloadIcon} alt='action' />}

                                <span className={button.type === 'get' || button.type === 'viewchange' ? 'get-button' : 'button-label'}>
                                    {button.type === 'get' && isMobile ? 'Get Solution' : button.name && button.type === 'viewchange' && isMobile ? (tabview ? 'Summary' : 'All') : button.name && button.type === 'download' && downloading ? "Downloading" : button.name}
                                </span>
                            </Button>
                        );
                    })}
                </div>
            </div>
            <div className='compliance-table-header'>
                <span className='company-column'>Company Name</span>
                <span className='gst-column'>GSTIN</span>
                <span className='status-column'>Status</span>
            </div>
            <div className='compliance-data-container'>
                {complianceData && complianceData.map((compli: any) => (
                    compli.gst.gst_no && compli.form_names && <div className='compliance-table-content'>
                        <div className='company-table-column'>
                            <span className='comapany-line'>{compli.gst.trade_name}</span>
                        </div>
                        <div className='gst-table-column'>
                            <span className='gst-line'>{compli.gst.gst_no}</span>
                        </div>
                        <div className='status-table-column'>
                            {compli.form_names && compli.form_names.map((form: any, index: number) => (
                                <div className={index === 1 ? 'status-second-row' : 'status-first-row'} key={form.formName}>
                                    <div className='row-header'>
                                        <span>{form.formName}</span>
                                    </div>
                                    <div className='row-card-wrap'>
                                        {form.retPrds.map((retPrd: any) => (
                                            <div className={retPrd.filingStatus !== 'Filed' ? 'row-card row-card-active' : 'row-card'} >
                                                <span className='row-card-mainline'>{retPrd.monthYearName}</span>
                                                <span className='row-card-subline'>{retPrd.filingStatus}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                ))}
                {loading &&
                    <Spin spinning={loading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '10%' }}>
                    </Spin>
                }
                {!loading && complianceData.length === 0 && <span className='nodata-line'>No data</span>}
            </div>
            {
                complianceData.length === 0 ? <></> : <div className='compliance-table-footer'>
                    <Pagination
                        pageSizeOptions={['5', '10', '20', '50', '100']}
                        total={totalpages}
                        current={page}
                        showTotal={(total, range) => `${isMobile ? '' : 'Showing'} ${range[0]} of ${total} ${isMobile ? '' : 'results'}`}
                        pageSize={rowsPerPage}
                        showSizeChanger={true}
                        onChange={(page, pageSize) => {
                            setPage(page);
                            setRowsPerPage(pageSize);
                        }}
                        onShowSizeChange={(current, size) => {
                            setPage(current);
                            setRowsPerPage(size);
                        }}
                    />
                </div>
            }
        </div >
    )
}