// react 
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './header.scss'
// ..........

// package components
import { Button, Modal, Spin } from 'antd'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'clipboard-copy';
import { Menu, MenuItem, Avatar, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
// ..........

// child components
import { useGlobalState } from '../../Statecontext'
import { serviceConfig } from '../../core/services'
import { NotifyAlert } from '../../components/notify_alert';
import { Utils } from '../../core/utils/utils'
import { UserLogout } from '../logout'
import { UserProfile } from '../profile'
// ..........

// icons & logo
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MenuIcon from '@mui/icons-material/Menu'
import GstDashWht from '../../assets/images/Gst_dash-White.svg'
import ManageGstIn from '../../assets/images/ManageGst.svg'
import DueDate from '../../assets/images/dueDate.svg'
import Complianceicon from '../../assets/GST-dash/compliance.png'
import Compliancewhticon from '../../assets/GST-dash/Compliance_wht.png'
import casesummaryicon from '../../assets/GST-dash/Casesummary.png'
import casesummaryiconWht from '../../assets/GST-dash/Casesummary_wht.png'
import DueDashWht from '../../assets/images/due_dash_wht.svg'
import gstDashboardIcon from '../../assets/images/gstDashboard.svg'
import LogoutIcon from '../../assets/images/logout.svg'
import ManageGstInBlk from '../../assets/GST-dash/Manage.png'
import ProfileIcon from '../../assets/images/profile.svg'
import SoluDashWht from '../../assets/images/solu_dash_wht.svg'
import SolutionDashboard from '../../assets/images/solutionDashboard.svg'
import PHDashboard from '../../assets/PH_Register/PH_dash_icon-wht.svg'
import PHDashboardBlk from '../../assets/PH_Register/PH_dash_icon-wht.svg'
import SyncGreen from '../../assets/images/syncGreen.svg'
import userProf from '../../assets/images/userProfile.svg'
import UsersIcon from '../../assets/images/users.svg'
import Logo from '../../assets/images/zentaxLogo.svg'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// ..........

export default function Header() {

	// form values states
	const [captchacnt, setCaptchacnt] = useState('')
	// ...........

	// alerts and modal states
	const [optOpen, setOptOpen] = useState(false)
	const [notifopen, setNotifopen] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [profileOpen, setProfileOpen] = useState(false)
	const [logoutOpen, setLogoutOpen] = useState(false)
	const [state, setState] = React.useState({ right: false });
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	// ...........

	//  datas states
	const [userRole, setUserRole] = useState<any>()
	const [userData, setUserData] = useState<any>()
	const [overDueNotices, setOverDueNotices] = useState<any>()
	const [pendingNotices, setPendingNotices] = useState<any>()
	let [syncId, setSyncId] = useState<any>(null)
	// ...........

	// loading states
	const [syncloading, setSyncloading] = useState(false)

	// reference states
	const navigate = useNavigate()
	const locations = useLocation()
	const location = useLocation()
	const optionsRef = useRef<any>(null);
	const notifRef = useRef<any>(null);
	const headerMasterLoading = useRef(false)
	const textRef = useRef<HTMLDivElement>(null);
	const [viewback, setViewback] = useState(false)
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const { progres, masterData, setMasterData, setFilterQuery, setSorterQuery, setSearchquery, syncedCount, setPage, setTotalpages, setTabactive, tabview, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setreportModal, reportmodal, syncDetails, setChangeview, setSyncDetails, setChartquery } = useGlobalState();
	const { getSyncCount, } = Utils();
	// ...........

	// data get function
	const captchacountfunc = () => {
		serviceConfig.get("captcha_count", true, null, null).then((data: any) => {
			setCaptchacnt(data.data.balance || 0)
		}).then((err: any) => {
		})
	}

	// data get function
	const getStatusCount = () => {
		serviceConfig.get("get_notices_status_count", true, null, null).then((data: any) => {
			// setCaptchacnt(data.data.balance || 0)
			console.log(data)
			setOverDueNotices(data.data.overdue_notices)
			setPendingNotices(data.data.pending_notices)
		}).then((err: any) => {
		})
	}

	const refreshUserData = () => {
		setUserData(JSON.parse(localStorage.getItem('userData') || ''))
	}

	// prevent filter values

	const localStorageKeys: any = {
		gst: 'masterData_gst',
		states: 'masterData_states',
		trades: 'masterData_trades',
		caseIdFilter: 'masterData_caseIdFilter',
		arnFilter: 'masterData_arnFilter',
		sessionFilter: 'masterData_sessionFilter',
		caseType: 'masterData_caseType',
		userNames: 'masterData_userNames'
	};

	// Effect to load data from localStorage when the component mounts
	useEffect(() => {
		const loadData = () => {
			Object.keys(localStorageKeys).forEach(key => {
				const savedData = localStorage.getItem(localStorageKeys[key]);
				if (savedData) {
					setMasterData((prevState: any) => ({
						...prevState,
						[key]: JSON.parse(savedData)
					}));
				}
			});
		};

		loadData();
	}, []);
	// ...........

	useEffect(() => {

		getStatusCount()
		captchacountfunc()
		setUserRole(localStorage.getItem('userRole') || '')
		setUserData(JSON.parse(localStorage.getItem('userData') || ''))
		!headerMasterLoading.current && getSyncCount()
		// setSyncedCount(parseInt(localStorage.getItem('syncedCount') || '0'))
		document.addEventListener('storage', event => {
			// if(syncedCount < parseInt(localStorage.getItem('syncedCount') || '0')){
			// setSyncedCount(parseInt(localStorage.getItem('syncedCount') || '0'))
			// }
		});

		function handleClickOutside(event: any) {
			if (optionsRef.current && !optionsRef.current?.contains(event.target)) {
				setOptOpen(false)
				setNotifopen(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsRef]);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
		setIsMobile(mediaQuery.matches);

		const handleResize = () => setIsMobile(mediaQuery.matches);

		mediaQuery.addListener(handleResize);

		return () => {
			mediaQuery.removeListener(handleResize);
		};
	}, []);
	// ...........

	useEffect(() => {

		const module: any = location.pathname.split('/').pop();
		const module1: any = location.pathname.split('/')[3];

		console.log(module, 'module')
		console.log(module1, 'module1')

		if (module === 'view-notices') {
			setViewback(true);
		}
		else if (module1 === 'view-solution-details') {
			setViewback(true);
		}
		else if (module === 'view-gst-details') {
			setViewback(true);
		}
		else if (module === 'case-summary-details') {
			setViewback(true);
		}
		else {
			setViewback(false);
		}
	}, [location])

	// drawer and modal functions
	const toggleDrawer =
		(anchor: any, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event &&
					event.type === 'keydown' &&
					((event as React.KeyboardEvent).key === 'Tab' ||
						(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}

				setState({ ...state, [anchor]: open });
			};

	const openPopup = (type: any) => {
		setOptOpen(false)
		type === 'profile' ? setProfileOpen(true) : setLogoutOpen(true);
	}
	// ...........

	// Copy to clipboard option function
	const handleCopyClick = () => {
		const content = userData.cf_code
		copy(content);
		setNotifyType("success")
		setNotifyMessage("Refer Code copied successfully.")
		setNotifyOpen(true)
	};
	// ..........

	// sub component
	const welcometext = () => {
		return (
			<>
				Welcome Back, <b> {userData ? userData.user_name : ''}.</b>
			</>
		)
	}
	// ...........

	// sub component
	const list = (anchor: any) => (
		<Box
			className="drawer"
			sx={{ width: 250, height: '100vh', display: 'flex', flexDirection: 'column' }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List >
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemText primary={welcometext()} />
					</ListItemButton>
				</ListItem>
			</List>
			<Divider />
			<List className='drawer-list'>
				<Button id="sync-btn" > <img src={SyncGreen} alt='' />GSTIN sync count - <b>{syncedCount}</b> </Button>
				{nav_btns.map((button: any) => (
					((userRole !== 'Super Admin') || (userRole === 'Super Admin' && button.name !== 'SOLUTION DASHBOARD')) &&
					<Button onClick={() => menuclick(button)} className={button.path.includes(locations.pathname) === false ? 'menu_btn' : 'menu_btn menu_btn_active'}> {button.path.includes(locations.pathname) === false ? <img src={button.icon} style={{ height: 18 }} alt='' /> : <img src={button.activeicon} style={{ height: 18 }} alt='' />}{button.name}</Button>
				))}

			</List>
			<Divider style={{ marginTop: 'auto' }} />
			<List >
				{userRole === 'Super Admin' && <ListItem disablePadding>
					<ListItemButton onClick={() => openPopup('logout')}>
						<ListItemText primary={`TC-Balance - $  ${captchacnt}`} />
					</ListItemButton>
				</ListItem>}
				{userRole === 'Super Admin' && <ListItem disablePadding>
					<ListItemButton
						onClick={() => {
							openPopup('logout');
							setFilterQuery({});
							setSorterQuery({})
							setSearchquery('')
							setPage(1);
							setTotalpages(0);
							setRowsPerPage(10);
							setTabview(false)
							setTabactive('GSTIN Summary')
							setChartquery(false)
							setSumdrawer(false);
							setSumpopup(false)
						}}>
						<ListItemIcon>
							<img src={UsersIcon} alt='UsersIcon' />
						</ListItemIcon>
						<ListItemText primary={'Users'} />
					</ListItemButton>
				</ListItem>}
				{userRole !== 'Super Admin' && <ListItem disablePadding>
					<ListItemButton onClick={() => openPopup('profile')}>
						<ListItemIcon >
							<img src={ProfileIcon} alt='ProfileIcon' />
						</ListItemIcon>
						<ListItemText primary={'Profile'} />
					</ListItemButton>
				</ListItem>}
				<ListItem disablePadding>
					<ListItemButton onClick={() => openPopup('logout')}>
						<ListItemIcon>
							<img src={LogoutIcon} alt='LogoutIcon' />
						</ListItemIcon>
						<ListItemText primary={'Logout'} />
					</ListItemButton>
				</ListItem>
			</List>
		</Box >
	);
	// ...........

	// button details props
	const nav_btns = [
		{
			name: 'GST DASHBOARD',
			path: ['/home/gst-dashboard', 'gst-dashboard', '', 'home', 'view-gst-details'],
			icon: gstDashboardIcon,
			activeicon: GstDashWht
		},
		{
			name: 'CASE SUMMARY',
			path: ['/home/case-summary', 'case-summary', 'view-notices', 'case-summary-details'],
			icon: casesummaryicon,
			activeicon: casesummaryiconWht
		},
		{
			name: 'TICKETS',
			path: ['/home/solution-dashboard', 'solution-dashboard', 'view-solution-details'],
			icon: SolutionDashboard,
			activeicon: SoluDashWht
		},
		{
			name: 'DUE CALENDAR',
			path: ['/home/due-dashboard', 'due-dashboard'],
			icon: DueDate,
			activeicon: DueDashWht
		},
		// {
		// 	name: 'AVAIL GST',
		// 	path: ['/home/avail-gst', 'avail-gst'],
		// 	icon: casesummaryicon,
		// 	activeicon: casesummaryiconWht
		// },
	]

	if (userRole !== 'Super Admin') {
		nav_btns.push(
			{
				name: 'COMPLIANCE MONITOR',
				path: ['/home/compliance', 'compliance'],
				icon: Complianceicon,
				activeicon: Compliancewhticon
			},
			{
				name: 'MANAGE GSTIN',
				path: ['/home/manage-gst', 'manage-gst'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			}
		)
	}
	else if (userRole === 'ph_partner') {
		nav_btns.push(
			{
				name: 'PH PARTNER DASHBOARD',
				path: ['/home/phdashboard', 'phdashboard'],
				icon: PHDashboardBlk,
				activeicon: PHDashboard
			},
			{
				name: 'TICKETS',
				path: ['/home/solution-dashboard', 'solution-dashboard'],
				icon: SolutionDashboard,
				activeicon: SoluDashWht
			},
		);
	}
	else {
		nav_btns.push(
			{
				name: 'MANAGE GSTIN',
				path: ['/home/manage-gst', 'manage-gst'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
			{
				name: 'USERS APPROVAL',
				path: ['/home/userapproval', 'userapproval'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
			{
				name: 'PH PARTNERS',
				path: ['/home/phpartners', 'phpartners'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
			{
				name: 'SYNC MONITORING',
				path: ['/home/syncmonitoring', 'syncmonitoring'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
		)
	}
	// ...........

	const popupFunc = (label: any) => {
		if (label === 'Sync') {
			setreportModal(true)
		}
	}

	const menuclick = (button: any) => {
		navigate(`${button.path[0]}`);
		setFilterQuery({});
		setSorterQuery({})
		setSearchquery('')
		setPage(1);
		setTotalpages(0);
		setRowsPerPage(10);
		setTabview(false)
		setTabactive('GSTIN Summary')
		setChartquery(false)
		setSumdrawer(false);
		setSumpopup(false)
		if (button.name === 'DUE CALENDAR') {
			setChangeview(true)
		} else {
			setChangeview(false)
		}
	}

	const getLastSyncStatus = () => {
		setSyncloading(true)
		serviceConfig.get("gst_sync", true, { 'last': true }, null).then((data: any) => {
			setSyncloading(false)
			if (data.length > 0) {
				setSyncDetails(data[0])
				if (!data[0].sync_processed && data[0].is_sync_all) {
					syncId = data[0].id
					setSyncId(data[0].id)
					getStatusofSyncAllProgress()
				}
			}
		}, (err: any) => {
			setSyncloading(false)
			setreportModal(false)
			setNotifyType("error")
			setNotifyMessage('Something Went Wrong!')
			setNotifyOpen(true)
		})
	}
	const getStatusofSyncAllProgress = () => {
		setSyncloading(true)
		serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
			setSyncDetails(data)
			setSyncloading(false)
		}, (err: any) => {
			setNotifyType("error")
			setNotifyMessage('Something Went Wrong!')
			setNotifyOpen(true)
			setSyncloading(false)
			setreportModal(false)
		})
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			<Modal
				title={"Sync Report"}
				onCancel={() => setreportModal(false)}
				width={'40%'}
				className='sync-report-popup'
				centered
				style={{ color: "#031742" }}
				open={reportmodal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div className='report-wrap'>
					{syncDetails ?
						<>
							<div className='report-title'>
								<h3>GSTIN Processed count</h3>
								<h3>First time sync</h3>
								<h3>Total notices fetched</h3>
								<h3>Successfully synced</h3>
								<h3>Sync failed</h3>
								<h3>Sync start time</h3>
								{syncDetails.sync_end && <h3>Sync end time</h3>}
							</div>
							<div className='report-cnt'>
								<h3>: {syncDetails.gst_list.length}</h3>
								<h3>: {syncDetails.gst_list.filter((sd: any) => sd.is_first_time).length}</h3>
								<h3>: {syncDetails.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0)}</h3>
								<h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && !sd.is_sync_failed).length}</h3>
								<h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && sd.is_sync_failed).length}</h3>
								<h3>: {new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString()}</h3>
								{syncDetails.sync_end && <h3>: {syncDetails.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '--'}</h3>}
							</div>
						</> :
						<Spin spinning={syncloading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute' }}>
						</Spin>
					}
				</div>

				<p><b>Note:</b> Incorrect password is not at all considered.</p>
			</Modal>
			<div id="gst-header">
				<div className="head-top">
					<div className='logo_box'>
						<img src={Logo} alt='logo' style={{ height: "70%", margin: "0%" }} />
					</div>
					<div className='profile_box'>
						<div className='sync-box' onClick={() => popupFunc(progres.data.label)}>
							{progres.data.loading &&
								<Button className="Progres-btn" style={{ marginRight: '10px' }}>
									< AutorenewIcon className='Progress-icon' />
									<b>{progres.data.label} Progressing</b>
								</Button>
							}
						</div>
						{userRole !== 'Super Admin' && <div className='sync-box'>
							<Button className="sync-btn" >
								<img src={SyncGreen} alt='' />GSTIN sync count - <b>{syncedCount}</b>
							</Button>
						</div>}
						<div className="profile-content">
							Welcome Back, <b> {userData ? userData.user_name : ''}</b>

							{/* <NotificationsActiveIcon className='notif_icon' onClick={(event: any) => { setAnchorEl(event.currentTarget) }} /> */}
							<Avatar className='avatar' onClick={() => setOptOpen(true)} > {userData ? userData.user_name.charAt(0) : ''}</Avatar>
						</div>
						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							style={{ width: '800px' }}
							open={open}
							onClose={() => setAnchorEl(null)}
							onClick={() => setAnchorEl(null)}
							PaperProps={{
								elevation: 0,
								sx: {
									textAlign: 'center',
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'&::before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 8,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							<div className='notif_popover'>
								<span>Notifications</span>
								<span className='link-item' onClick={() => { getLastSyncStatus(); popupFunc('Sync'); setNotifopen(false); }}>
									Last Sync Report
								</span>
								<div className='notify-wrap'>
									<div className='notify-block'>

									</div>
								</div>
							</div>
						</Menu >
						{optOpen && <div className='drop_options' ref={optionsRef}>
							{userRole === 'Super Admin' && <div className='optionss' >
								<span>TC-Balance <b>${captchacnt}</b></span>
							</div>}
							{userData.is_cf === true &&
								<div className='optionss' >
									<span className='refer-line'>Refer Code <b>{userData.cf_code ? userData.cf_code : '-'}</b></span>
									<ContentCopyIcon fontSize='small' className='clipboard-icon' onClick={handleCopyClick} />
								</div>
							}
							{(userData.is_cf === true || userRole === 'Super Admin') && <div className='optionss' onClick={() => { navigate('/home/users'); setOptOpen(!optOpen); setFilterQuery({}); setPage(1); setTotalpages(0); setSumdrawer(false); setRowsPerPage(10) }}>
								<span><img src={UsersIcon} alt='userIcon' /> Users</span>
							</div>}
							{userRole !== 'Super Admin' && <div className='optionss' onClick={() => { navigate('/home/syncmonitoring'); setOptOpen(!optOpen); setFilterQuery({}); setPage(1); setTotalpages(0); setSumdrawer(false); setRowsPerPage(10) }}>
								<span>Sync Monitoring</span>
							</div>}
							{userRole !== 'Super Admin' && <div className='optionss' onClick={() => { openPopup('profile'); setOptOpen(!optOpen); setSumdrawer(false); setRowsPerPage(10) }}>
								<span><img src={ProfileIcon} alt='ProfileIcon' /> Profile</span>
							</div>}
							<div className='optionss' onClick={() => { openPopup('logout'); setOptOpen(!optOpen) }}>
								<span><img src={LogoutIcon} alt='LogoutIcon' /> Logout</span>
							</div>
						</div>}
					</div>
				</div >
				<div className="head-bottom">
					{viewback && <div className="notice-left-container">
						<Button onClick={() => { navigate(-1) }} className='back-nav'><ArrowBackIosNewIcon fontSize='small' className='icon' />{!isMobile && userRole !== 'Super Admin' && 'Back'}</Button>
					</div>}
					<div className="nav-button-box">
						{nav_btns.map((button: any) => (
							((userRole !== 'Super Admin') || (userRole === 'Super Admin' && button.name !== 'SOLUTION DASHBOARD')) &&
							<Button
								key={button.name}
								onClick={() => menuclick(button)}
								className={button.path.includes(location.pathname.split('/').pop()) === false ? 'menu_btn' : 'menu_btn menu_btn_active'}>
								{button.path.includes(location.pathname.split('/').pop()) === false ?
									<img src={button.icon} style={{ height: 18 }} alt='' /> :
									<img src={button.activeicon} style={{ height: 18 }} alt='' />}
								{button.name}
							</Button>
						))}
					</div>
				</div>
			</div >
			<div id='mob-gst-header'>
				<div className='logo_box'>
					<img src={Logo} alt='logo' style={{ height: "70%", margin: "1% 0 0 3%" }} />
				</div>
				<MenuIcon className='header-menu' onClick={toggleDrawer('right', true)} />
			</div>
			<Drawer
				anchor={'right'}
				open={state['right']}
				onClose={toggleDrawer('right', false)}
			>
				{list('right')}
			</Drawer>
			<UserProfile open={profileOpen} setOpen={setProfileOpen} refresh={refreshUserData}></UserProfile>
			<UserLogout openLogout={logoutOpen} setLogoutOpen={setLogoutOpen}></UserLogout>
			<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
		</>

	)
}
