// react
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './index.scss';
// .........

// package components
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
// .........

// Custom dialog component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
// .........

export const UserLogout = ({ openLogout, setLogoutOpen }: any) => {

    // reference states
    const navigate = useNavigate()
    // .........

    // modal close function
    const handleClose = () => {
        setLogoutOpen(false);
    };
    // .........

    // logout function
    const logoutUser = () => {
        setLogoutOpen(false);
        localStorage.clear();
        navigate('/login')
    }
    // .........

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby='customized-dialog-title'
            open={openLogout}
            id='logout_container'>
            <DialogTitle
                sx={{ m: 0, p: 1 }}
                className='logout-head'>
                Confirmation
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 0,
                    color: (theme) => theme.palette.grey[500]
                }}>
            </IconButton>
            <DialogContent
                dividers
                style={{ padding: "0px 20px 11px 20px" }}>
                <p className="logout-msg">Are you sure want to logout ?</p>
                <div>
                    <button
                        className='login-btn no-btn'
                        onClick={logoutUser}>Yes</button>
                    <button
                        className="login-btn"
                        onClick={handleClose}>No</button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    )
}