// react 
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './phdashboard.scss';
// .........

// package components
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
// .........

// child components
import { useGlobalState } from '../../Statecontext';
import Chart from '../../components/chart';
// .........

// icons
import arrowRightIcon from '../../assets/images/chevron-right.svg'
import { Spin, Table, Tag } from 'antd';
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
// .........

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const Phdashboard = () => {

    // form values states

    // .........

    // data states
    const [gstCounts, setGstCounts] = useState<any>()
    const [noticeData, setNoticeData] = useState<any>([]);
    const { filterQuery, setFilterQuery, totalpages, setTotalpages } = useGlobalState();
    // .........

    // alerts and modal states
    // .........

    // loading state
    const [loading, setLoading] = useState(false)
    // .........

    // limit & filter states
    const gstTableElement = useRef<any>();
    // .........

    // reference states
    const navigate = useNavigate()

    useEffect(() => {
        getGSTCounts()
    }, []);
    // view data function

    // ............

    // filter adding from chart and other datas function
    const setqueryvalue = (query: any) => {
        const updatedQuery = {
            ...filterQuery,
            gst_no: null,
            tax_amount: null,
            tax_period: null,
            trade_name: null,
            notice_status: [query]
        };
        setFilterQuery(updatedQuery)
    }

    const handleChartLabelClick = (label: string) => {
        const updatedQuery = {
            ...filterQuery,
            gst_no: null,
            tax_amount: null,
            tax_period: null,
            trade_name: null,
            notice_status: [label]
        };
        setFilterQuery(updatedQuery)
    };
    // ............

    // data get function
    const getGSTCounts = useCallback(() => {
        serviceConfig.get("get_gst_counts", true, null, null).then((data: any) => {
            setGstCounts(data.data)
        }, (err: any) => {
            console.log(err)
            if(err.message === "Request failed with status code 401"){
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
        })
    }, [])
    // ............

    // card data details
    const dash_cards = [
        {
            label: 'Total pending tasks',
            theme: 'theme1',
            icon: require('../../assets/PH_Register/pending-task.svg'),
            value: '25'
        },
        {
            label: 'CGST',
            theme: 'theme2',
            icon: require('../../assets/PH_Register/cgst.svg'),
            value: '10'
        },
        {
            label: 'SGST',
            theme: 'theme3',
            icon: require('../../assets/PH_Register/sgst.svg'),
            value: '15'
        },
        {
            label: 'Registration Cancellation',
            theme: 'theme4',
            icon: require('../../assets/PH_Register/registration-cancel.svg'),
            value: '05'
        },
        {
            label: 'Submission of details',
            theme: 'theme5',
            icon: require('../../assets/PH_Register/submission.svg'),
            value: '03'
        },
        {
            label: 'Notice related PH',
            theme: 'theme6',
            icon: require('../../assets/PH_Register/notice_ph.svg'),
            value: '03'
        },
        {
            label: 'Appeal related PH',
            theme: 'theme7',
            icon: require('../../assets/PH_Register/appeal.svg'),
            value: '03'
        },
        {
            label: 'Inspection / Audit',
            theme: 'theme8',
            icon: require('../../assets/PH_Register/Ins_Aud.svg'),
            value: '03'
        },
    ]
    // .............

    // button details props
    const buttondetails: any = []
    // .............

    const Column = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
            width: 10
        },
        {
            title: 'PH Type',
            dataIndex: 'gst', key: 'trade_name',
            maxWidth: 180,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span>)
            },
            width: 180
        },
        {
            title: 'CGST / SGST',
            dataIndex: 'gst', key: 'trade_name',
            maxWidth: 180,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span>)
            },
            width: 180
        },
        {
            title: 'Type',
            dataIndex: 'gst', key: 'trade_name',
            maxWidth: 160,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span>)
            },
            width: 160
        },
        {
            title: 'Due date', dataIndex: 'due_date', key: 'address',
            maxWidth: 120,
            width: 120
        },
        {
            title: 'Next visit date', dataIndex: 'due_date', key: 'address',
            maxWidth: 120,
            width: 120
        },
        {
            title: 'Client name',
            dataIndex: 'gst', key: 'trade_name',
            maxWidth: 200,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span>)
            },
            width: 200
        },
        {
            title: 'Status', dataIndex: 'notice_status', key: 'notice_status',
            maxWidth: 180,
            width: 180
        },
        Table.SELECTION_COLUMN,
    ];
    return (
        <div className='ph-dashboard-container'>
            <div className='ph-dashboard-data-cover'>
                <div className='ph-data-cards'>
                    {dash_cards.map((card: any, index: any) => (
                        <div className={`data-card-wrap ${card.theme}`} key={index} >
                            <p style={{ color: 'rgba(102, 102, 102, 1)' }}>{card.label}</p>
                            <div className='value-icn-wrap'>
                                <img alt='icon' src={card.icon} style={{ height: '40px', width: '40px' }} />
                                <span>{card.value}</span>
                            </div>
                        </div>))}
                </div>
                <div className='ph-chart-wrap'>
                    <div className="stats-box" >
                        {gstCounts ?
                            <>
                                <div className="gst-chart">
                                    <Chart className='chart' counts={[gstCounts.no_action_required, gstCounts.action_taken, gstCounts.action_to_be_taken]} onLabelClick={handleChartLabelClick} />
                                </div>
                                <div className="chart-stat">
                                    <div className="stat-item" onClick={() => setqueryvalue('Completed')}>
                                        <div className="stat-sub">
                                            <span className="dot" style={{ backgroundColor: "#299CDB" }}></span>
                                            <p>Completed</p>
                                        </div>
                                        <div className="stat-subs">
                                            <span style={{ color: '#000' }}>{gstCounts.no_action_required || 0}</span>
                                            <img src={arrowRightIcon} alt="" />

                                        </div>

                                    </div>
                                    <div className="stat-item" onClick={() => setqueryvalue('To visit again')}>
                                        <div className="stat-sub">
                                            <span className="dot" style={{ backgroundColor: "#F06548" }}></span>
                                            <p>To visit again </p>
                                        </div>
                                        <div className="stat-subs">
                                            <span style={{ color: '#000' }}>{gstCounts.action_to_be_taken || 0}</span>
                                            <img src={arrowRightIcon} alt="" />

                                        </div>

                                    </div>
                                    <div className="stat-item" onClick={() => setqueryvalue('NOT yet visited')}>
                                        <div className="stat-sub">
                                            <span className="dot" style={{ backgroundColor: "#0AB39C" }}></span>
                                            <p>NOT yet visited</p>
                                        </div>
                                        <div className="stat-subs">
                                            <span style={{ color: '#000' }}>{gstCounts.action_taken || 0}</span>
                                            <img src={arrowRightIcon} alt="" />

                                        </div>

                                    </div>
                                </div>
                            </> :
                            <Spin spinning={true} size='default' >
                            </Spin>
                        }

                    </div>
                </div>
            </div>
            <ZTable2
                label={'PH Dashboard'}
                button={buttondetails}
                columns={Column}
                expand={false}
                ref={gstTableElement}
                tabledata={noticeData}
                loading={loading}
                totalpages={totalpages} />
        </div >
    )
}