// react 
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './userapproval.scss';
// .........

// package components
import { DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
// .........

// child components
import { AppContext } from '../../core/utils/Context';
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
import { useGlobalState } from '../../Statecontext';
// .........

// icons
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import filterIcon from '../../assets/images/filter.svg';
import { Button, Spin } from 'antd';
// .........

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const Userapproval = () => {

    // form values states
    const [usersList, setUsersList] = useState<any>([])
    const [userData, setUserData] = useState<any>()
    const [err_user_name, setErrUserName] = useState('')
    const [user_name, setUsername] = useState('')
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber, remarks } = formValues;
    // .........

    // data states
    const [sel_gst_no, setSelGST] = useState('')
    const [cgstcheck, setCgstcheck] = useState('Daily');
    const [sgstcheck, setSgstcheck] = useState('Daily');
    const [gstcheck, setGstcheck] = useState('Fresher');
    const [relcheck, setRelcheck] = useState('CGST')
    const [controlcheck, setControlcheck] = useState('Basic')
    const [inputValue, setInputValue] = useState<string>('');
    const [phdata, setPhdata] = useState<any>({})
    // .........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [phpopup, setPhpopup] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .........

    // loading state
    const [loading, setLoading] = useState(false)
    // .........

    // limit & filter states
    const { filterQuery, searchquery, page, totalpages, setTotalpages, rowsPerPage, userdata, setUserdata } = useGlobalState();
    // .........

    // reference states
    const navigate = useNavigate()
    // .........

    // view data function

    // ............

    // data get function
    const loadUser = useCallback((search_data?: any) => {

        setLoading(true)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }

        if (searchquery) {
            query['search'] = searchquery;
        }
        query['approval_status'] = ['pending', 'hold']
        if (userdata && userdata.is_cf === true) {
            query['cf_code'] = userdata.cf_code
        }

        serviceConfig.get("ph_register", true, query, null).then((data: any) => {
            setTotalpages(data.count)
            setUsersList(data.results || data.data)
            setLoading(false)
        }, (err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filterQuery, userdata,searchquery])

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('userData') || ''))
        loadUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterQuery])

    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    const latitude = 37.7749; // Example latitude
    const longitude = -122.4194; // Example longitude

    useEffect(() => {
        if (!mapRef.current) return;
        const googleMap = new google.maps.Map(mapRef.current, {
            center: { lat: latitude, lng: longitude },
            zoom: 10, // adjust as needed
        });
        setMap(googleMap);

        // Add marker
        const marker = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            map: googleMap,
            title: 'Your Location',
        });

        return () => {
            // Cleanup
            google.maps.event.clearInstanceListeners(googleMap);
        };
    }, [latitude, longitude]);
    // .........

    // approvefuction
    const handleapproval = (data: any) => {
        setPhpopup(true);
        setPhdata(data);
    }
    // ..........

    //  function Ph approval 
    const Phapproval = (approv: any) => {
        setLoading(true)
        let body: any = {
            id: phdata.id,
            full_name: phdata.full_name,
            email: phdata.email,
            experience: phdata.experience,
            address: phdata.address,
            phone_no: phdata.phone_no,
            org_name: phdata.org_name,
            qualification: phdata.qualification,
            team_size: phdata.team_size,
            pin_code: phdata.pin_code,
            city: phdata.city,
            alt_phone_no: phdata.alt_phone_no,
            depth_gst: phdata.depth_gst,
            exist_fre_visit_sgst: phdata.exist_fre_visit_sgst,
            exist_fre_visit_cgst: phdata.exist_fre_visit_cgst,
            area_of_practices: phdata.area_of_practices,
            control_over_gst: controlcheck,
            exist_good_relation: relcheck,
            remarks: remarks.value,
            approval_status: approv,
        }

        serviceConfig.put('ph_approval', true, body, null, { id: phdata.id }).then((res: any) => {
            console.log("response", res);
            handleClose()
            setLoading(false)
            loadUser()
        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage(err)
                setNotifyOpen(true)
                setLoading(false)
            }
        )
    }
    // ..........

    // table columns and filter requirement props
    const columns: any = [
        {
            title: 'S No.',
            dataIndex: '',
            render(value: any, record: any, index: any) {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'PH Partner Name',
            dataIndex: 'full_name',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Organization Name',
            dataIndex: 'org_name',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_no',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Pincode',
            dataIndex: 'pin_code',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Depth of GST Practice',
            dataIndex: 'depth_gst',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: "Action",
            dataIndex: "sync_status",
            fixed: "right",
            render(value: any, record: any, index: any) {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} >
                        <RemoveRedEyeIcon fontSize='medium' className='syncview-icon' onClick={() => { handleapproval(record) }} />
                    </div>
                )
            }
        },
    ];
    // .........

    // button details props
    const buttondetails: any = []
    // .........

    const rejectfunction = () => {
        Phapproval('reject')
    }

    const holdfunction = () => {
        Phapproval('hold')
    }

    const acceptfunction = () => {
        Phapproval('approved')
    }

    const handleClose = () => {
        setPhpopup(false);
        setControlcheck('Basic');
        setRelcheck('CGST')
        handleInitial()
    }
    //approval button details 
    const approv_buttondetails: any = [
        {
            type: 'reject',
            name: 'Reject',
            theme: 'theme3',
            function: rejectfunction
        },
        {
            type: 'hold',
            name: 'Keep on hold',
            theme: 'theme4',
            function: holdfunction
        },
        {
            type: 'accept',
            name: 'Accept as PH Partner',
            theme: 'theme2',
            function: acceptfunction
        },
    ]
    // .........

    //checkbox
    const checkboxData = [
        { label: 'Basic' },
        { label: 'Intermediate' },
        { label: 'Pro' },
        { label: 'GST Faculty' },
        { label: 'High Court Junior Adv' },
        { label: 'High Court Experienced Adv' },
        { label: 'Senior Adv' }
    ];
    const checkboxData1 = [
        { label: 'CGST' },
        { label: 'SGST' },
        { label: 'Both' },
    ];
    // ..........


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={phpopup}
                id='Approv_ph_partner_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    Approval process
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='dialog-content' style={{ marginTop: '0%' }}>
                    <div className="approve-admin-cover">
                        <div className='approve-form'>
                            <div className='ph-content-split'>
                                <div className="fields">
                                    <div className="row">
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                                            <label className="label">Full Name</label>
                                            <span>{fullname.error}</span>
                                            <div className="input-box" >
                                                {/* <img className="icons" src={fullnameIcon} alt='usernameIcon'></img> */}
                                                <input
                                                    type="text"
                                                    readOnly
                                                    placeholder="Enter your full name"
                                                    value={phdata.full_name}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name="fullname"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Email</label>
                                            <span>{email.error}</span>
                                            <div className="input-box" >
                                                {/* <img className="icons" src={emailicon} alt='emailicon'></img> */}
                                                <input
                                                    type="text"
                                                    readOnly
                                                    placeholder="Enter your Email"
                                                    value={phdata.email}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}
                                                    autoComplete='off'
                                                    name="email" ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Experience</label>
                                            <span>{experience.error}</span>
                                            <div className="input-box" >
                                                {/* <img className="icons" src={Exp_icon} alt='Exp_icon'></img> */}
                                                <input
                                                    type="text"
                                                    readOnly
                                                    placeholder="Enter your experience"
                                                    name='experience'
                                                    value={phdata.experience}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Address</label>
                                            <span>{address.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    readOnly
                                                    placeholder="Enter your user name"
                                                    name='address'
                                                    value={phdata.address}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Phone Number</label>
                                            <span>{ticContactNumber.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    readOnly
                                                    placeholder="Enter your phone number"
                                                    name='ticContactNumber'
                                                    value={phdata.phone_no}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}
                                                ></input>
                                            </div>
                                        </div>
                                        {/* <div className='Nearest-office-cover'>
                                            <span className='Nearest-office-heading'>Nearest CGST Office & Address with approx. distance from your office:</span>
                                            <ol>
                                                <li></li>
                                                <li></li>
                                            </ol>
                                        </div>
                                        <div className='Nearest-office-cover'>
                                            <span className='Nearest-office-heading'>Nearest CGST Office & Address with approx. distance from your office:</span>
                                            <ol>
                                                <li></li>
                                                <li></li>
                                            </ol>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='ph-content-split'>
                                <div className="fields">
                                    <div className="row">
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                                            <label className="label">Organisation Name</label>
                                            <span>{organisation_name.error}</span>
                                            <div className="input-box" >
                                                <input
                                                    type="text"
                                                    readOnly
                                                    placeholder="Enter your organisation name"
                                                    name='organisation_name'
                                                    value={phdata.org_name}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Qualification</label>
                                            <span>{qualification.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    readOnly
                                                    placeholder="Enter your qualification"
                                                    name='qualification'
                                                    value={phdata.qualification}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Team Size</label>
                                            <span>{team_size.error}</span>
                                            <div className="input-box" >
                                                <input
                                                    type="text"
                                                    readOnly
                                                    placeholder="Enter your team size"
                                                    name='team_size'
                                                    value={phdata.team_size}
                                                    onBlur={handleChange}
                                                    onChange={handleChange} ></input>
                                            </div>
                                        </div>
                                        <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem', width: '48%' }}>
                                                <label className="label">Pin Code</label>
                                                <span>{pin_code.error}</span>
                                                <div className="input-box" >
                                                    <input type="text"
                                                        readOnly
                                                        placeholder="Enter your pin code"
                                                        name='pin_code'
                                                        value={phdata.pin_code}
                                                        onBlur={handleChange}
                                                        onChange={handleChange} ></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem', width: '48%' }}>
                                                <label className="label">City</label>
                                                <span>{city.error}</span>
                                                <div className="input-box" >
                                                    <input type="text"
                                                        readOnly
                                                        name='city'
                                                        value={phdata.city}
                                                        placeholder="Enter your city"
                                                        onBlur={handleChange}
                                                        onChange={handleChange} ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                            <label className="label">Alternative Phone Number</label>
                                            <span>{alt_contactnumber.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    readOnly
                                                    placeholder="Enter your alter phone number"
                                                    name='alt_contactnumber'
                                                    value={phdata.alt_phone_no}
                                                    onBlur={handleChange}
                                                    onChange={handleChange}  ></input>
                                            </div>
                                        </div>

                                        {/* <div className='g-location-cover-wrap'>
                                            <div className='g-location-field'>
                                                <span>Google Location : </span>
                                                <span className='location-link'></span>
                                            </div>
                                            <div className='practice-area-field'>
                                                <span>Area of Practice :</span>
                                                <span></span>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="col-sm-12 input-group " id="last-one" >
                                <button className="register_ph" onClick={Phapproval}>{!loading ? 'Accept as PH Partner' : 'Loading...'}</button>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ height: '23vh', maxWidth: '100%', display: 'flex' }}>
                            <div ref={mapRef} style={{ width: '100%', height: '400px' }} />
                        </div> */}
                        <div className='admin-fields'>
                            <span className='admin-field-heading'>To be filled by super admin</span>
                            <div className='checkbox-cover'>
                                <div className='control-check-cover'>
                                    <span className='check-label'>Control over GST :</span>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData.map((item, index) => (
                                            <div className='checkbox_cover' key={index} onClick={() => setControlcheck(item.label)}>
                                                <span
                                                    className={controlcheck === item.label ? 'checked_box' : 'check_box'}
                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='relationship-check-cover'>
                                    <span className='check-label'>Existing Good rlationshipt with :</span>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData1.map((item, index) => (
                                            <div className='checkbox_cover' key={index} onClick={() => setRelcheck(item.label)}>
                                                <span
                                                    className={relcheck === item.label ? 'checked_box' : 'check_box'}
                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='remarks-cover-wrap'>
                                <div className='remarks-field'>
                                    <span className='remarks-label'>Other Remarks :</span>
                                    <div className="input-box" >
                                        <input type="text"
                                            autoComplete='off'
                                            name='remarks'
                                            value={remarks.value}
                                            onBlur={handleChange}
                                            onChange={handleChange}  ></input>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {loading &&
                            <Spin
                                spinning={loading}
                                size='default'
                                style={{
                                    maxWidth: '100%',
                                    display: 'flex',
                                    position: 'absolute',
                                    marginLeft: '32.8vw',
                                    marginTop: "28vh"
                                }}>
                            </Spin>
                        }
                    </div>
                    <div className='approv-submit-wrap'>
                        {approv_buttondetails.map((button: any) => (
                            <Button
                                id="z-common-gst-solution-button"
                                className={button.theme}
                                key={button.name}
                                onClick={(e) => { button.function() }}
                            >
                                <span >
                                    {button.name}
                                </span>
                            </Button>
                        ))}
                    </div>
                </DialogContent>
            </BootstrapDialog >
            <div className='users-approval-container'>
                <ZTable2 label={'Users Approval'} button={buttondetails} columns={columns} expand={false} hideSelect={true} loading={loading} tabledata={usersList} totalpages={totalpages} />
            </div>
        </>
    )
}