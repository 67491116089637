// react 
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './login.scss';
// ..........

// package components
import { FormHelperText, IconButton, InputAdornment, OutlinedInput, ThemeProvider, createTheme } from '@mui/material';
// ..........

// child components
import { useGlobalState } from '../../../Statecontext';
import passwordIcon from '../../../assets/register/password.png';
import { NotifyAlert } from '../../../components/notify_alert';
import { serviceConfig } from "../../../core/services";
// ..........

// icons
import mailIcon from '../../../assets/register/mail.png';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// ..........

export const Login = () => {

    // form values states
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [err_email, setErrEmail] = useState('')
    const [err_pswd, setErrPswd] = useState('')
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // data states
    const [showPassword, setShowPassword] = React.useState(false);
    // ..........

    // reference states
    const { setVideopopup, setUserdata, masterData, setMasterData, setRowsPerPage, setTotalpages, setPage } = useGlobalState();
    const navigate = useNavigate()
    // ..........

    useEffect(() => {

        if (localStorage.getItem('authKey') !== null) {
            navigate('/home/gst-dashboard');
        }

        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                loginUser()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, password]);

    // password field typechange icon function
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    // ..........

    // form value validation function
    const validateEmail = (email: any) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    const handleEmailChange = (e: any) => {
        const newval = e.target.value;
        setEmail(newval);
        if (newval !== '') {
            setErrEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        } else {
            setErrEmail("*Please enter registered mail id")
        }
    }

    const handlePassword = (e: any) => {
        const newval = e.target.value;
        setPassword(newval);
        if (newval !== '') {
            setErrPswd('')
        } else {
            setErrPswd("*Please enter your password")
        }

    }
    // ..........

    // master Data for filter
    const loadMasterData = useCallback(() => {
        const fetchData = async () => {
            try {
                const gstData = await serviceConfig.get("master_gst", true, null, null);
                const statesData = await serviceConfig.get("master_states", true, null, null);
                const tradesData = await serviceConfig.get("master_trades", true, null, null);
                const caseIdData = await serviceConfig.get("get_case_id", true, null, null);
                const arnData = await serviceConfig.get("get_arn", true, null, null);
                const sessionData = await serviceConfig.get("get_session", true, null, null);
                const caseTypeData = await serviceConfig.get("get_notice_type", true, null, null);
                const usernamesData = await serviceConfig.get("master_usernames", true, null, null);

                setMasterData({
                    gst: gstData.data,
                    states: statesData.data,
                    trades: tradesData.data,
                    caseIdFilter: caseIdData.data,
                    arnFilter: arnData.data,
                    sessionFilter: sessionData.data,
                    caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
                    userNames: usernamesData.data
                });
                localStorage.setItem('masterData', JSON.stringify({
                    gst: gstData.data,
                    states: statesData.data,
                    trades: tradesData.data,
                    caseIdFilter: caseIdData.data,
                    arnFilter: arnData.data,
                    sessionFilter: sessionData.data,
                    caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
                    userNames: usernamesData.data
                }));

            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [])
    // ..........


    // login submission function
    const loginUser = (e?: any) => {
        if (!loading) {
            if (!email || !password) {
                !email && setErrEmail('*Please enter registered mail id')
                !password && setErrPswd('*Please enter your password')
            }
            else {
                setLoading(true)
                let req_body = {
                    'email': email.toLowerCase(),
                    'password': password
                }

                serviceConfig.post('login_user', true, req_body, null).then((res: any) => {
                    setPage(1);
                    setTotalpages(0);
                    setRowsPerPage(10);
                    localStorage.setItem('authKey', res.data.auth_key)
                    setUserdata(res.data.user_data)
                    localStorage.setItem('userData', JSON.stringify(res.data.user_data))
                    localStorage.setItem('is_cf', JSON.stringify(res.data.user_data.is_cf))
                    localStorage.setItem('cf_code', JSON.stringify(res.data.user_data.cf_code))
                    localStorage.setItem('userRole', res.data.user_data.role.role_name)
                    localStorage.setItem('syncedCount', JSON.stringify(res.data.user_data.sync_count))
                    if (res.data.user_data.role.role_name !== 'Super Admin') {
                        loadMasterData()
                    }
                    if (res.data.is_gst_available === true) {
                        navigate('/home/gst-dashboard');
                    } else {
                        navigate('/home/manage-gst');
                        if (res.data.user_data.first_login === true) {
                            setVideopopup(true)
                        }
                    }
                    setLoading(false)
                },
                    (err: any) => {
                        setNotifyType("error")
                        setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                        setNotifyOpen(true)
                        setLoading(false)
                    }
                )
            }
        }
    }
    // ..........

    // custom theming function
    const inputtheme = createTheme({
        palette: {
            primary: {
                main: '#9a9999',
            }
        },
    });
    // ..........

    return (
        <>
            <div className="container-login">
                <h4 className="terminal-window-mainline">Welcome Back</h4>
                <p className="terminal-window-subline">Sign in to continue to Zen Tax Clinic</p>
                <div className="fields">
                    <div className="row">
                        {/* <form onSubmit={loginUser}> */}
                        <div className="col-sm-12 input-group">
                            <label className="input-label">E-Mail</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter your registered mail id'
                                    value={email}
                                    error={!!err_email}
                                    onChange={handleEmailChange}
                                    startAdornment={<img className="input-icon" src={mailIcon} alt='mail_icon' />}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                            </ThemeProvider>
                            <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_email}</FormHelperText>
                            {/* <div className="input-box" >
                                <img className="input-icon" src={mailIcon} alt='mail_icon'></img>
                                <input className='inputfield' type="text" value={email} onBlur={handleEmailChange} onChange={handleEmailChange} placeholder="Enter your registered mail id"></input>
                                <span className='input-error-line'>{err_email}</span>
                            </div> */}
                        </div>

                        <div className={err_email ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_email ? '0' : '' }}>
                            <label className="input-label">Password</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter your password'
                                    value={password}
                                    type={showPassword ? 'text' : 'password'}
                                    error={!!err_pswd}
                                    onChange={handlePassword}
                                    startAdornment={<img className="input-icon" src={passwordIcon} alt='password_icon' />}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                                <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_pswd}</FormHelperText>
                            </ThemeProvider>
                            {/* <div className="input-box" >
                                <img className="input-icon" src={passwordIcon} alt='password_icon'></img> */}
                            {/* <i className="fa fa-search"></i> */}
                            {/* <input className='inputfield' type={password_type} value={password} onBlur={handlePassword} onChange={handlePassword} placeholder="Enter your password"></input>
                                <span className='input-error-line'>{err_pswd}</span>
                                {password_type === 'password' ? <VisibilityOutlinedIcon onClick={() => setPasswordType('text')} className='input-end-icon' /> : <VisibilityOffOutlinedIcon onClick={() => setPasswordType('password')} className='input-end-icon' />}
                            </div>  */}
                        </div>

                        <div className="col-sm-12  forgot-link">
                            <Link className="terminal-link" to='/forgot-password'>Forgot Password?</Link>
                        </div>

                        <div className="col-sm-12 input-group">
                            <button type='submit' className="teminal-primary-btn" onClick={loginUser}>{loading ? 'Loading...' : 'Login'}</button>
                        </div>
                        {/* </form> */}

                        <div className="col-sm-12 terminal-divider" style={{ paddingTop: '0.8rem' }}>
                            <hr></hr>
                        </div>

                        <div className="col-sm-12 input-group">
                            <button className="teminal-primary-btn teminal-secondary-btn" onClick={() => navigate('/register')} >Create new account</button>
                        </div>

                    </div>
                </div>

            </div>

            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}