// react 
import { Outlet } from 'react-router-dom';
// ..........

// scss file
import './terminal.scss';
// ..........

// icons
import newimg from '../../assets/terminalImages/banner.png';
import icon1 from '../../assets/terminalImages/icon1.svg';
import icon2 from '../../assets/terminalImages/icon2.png';
import icon3 from '../../assets/terminalImages/icon3.svg';
import icon4 from '../../assets/terminalImages/icon4.png';
import logo_img from '../../assets/terminalImages/zlogo.svg';
// ..........

// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';


// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     // textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));

export const Terminal = () => {
    return (
        <>
            <div className="container" >
                <div className="default-container">
                    <img className='logo-image' src={logo_img} alt='logo' />
                    <img className="gst-img" src={newimg} alt='gst_img'></img>
                    <div className="load-container">
                        <div className="footer-top">
                            <p>Check for GST notices across multiple GSTINs in less than 5 minutes for <span>FREE</span></p>
                        </div>
                        <div className="footer-bottom">
                            <div className="grid-container">
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon1} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">Speed and Efficiency</div>
                                            <div className="subTitle">Facilitates prompt review and swift action on GST notices or Orders</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon2} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">Comprehensive Monitoring</div>
                                            <div className="subTitle">Our due date calendar ensures you stay on top of every notice and compliance requirement</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo"  style={{margin:'auto 0 auto 0'}}>
                                            <img src={icon3} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">User-Friendly Interface</div>
                                            <div className="subTitle">Easy to navigate and requires no technical skills</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon4} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">Expert Support</div>
                                            <div className="subTitle">Access to our team of GST professionals for any assistance you may need</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="login-container">
                    <a className="site-url" href="https://www.zentaxclinic.com/" target="_blank" > <div className="site-link-btn site-tab">www.zentaxclinic.com</div> </a>
                    <Outlet />
                </div>
            </div>
            <div id="mob-container" >
                <div className="default-container">
                    <a href="https://www.zentaxclinic.com/" target="_blank" ><div className="site-link-btn site-tab">www.zentaxclinic.com</div> </a>
                    <div className='gst-img'>
                        <img className='logo-image' src={logo_img} alt='logo' />
                    </div>
                    {/* <img className="gst-img" src={gstImg} alt='gst_img'></img> */}
                    <div className="login-container">
                        <Outlet />
                    </div>
                    <div className="load-container">
                        <div className="footer-top">
                        <p>Check for GST notices across multiple GSTINs in less than 5 minutes for <span>FREE</span></p>
                        </div>
                        <div className="footer-bottom">
                            <div className="grid-container">
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon1} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">Speed and Efficiency</div>
                                            <div className="subTitle">Facilitates prompt review and swift action on GST notices or Orders</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon2} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">Comprehensive Monitoring</div>
                                            <div className="subTitle">Our due date calendar ensures you stay on top of every notice and compliance requirement</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon3} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">User-Friendly Interface</div>
                                            <div className="subTitle">Easy to navigate and requires no technical skills</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="footer-box">
                                        <div className="logo">
                                            <img src={icon4} alt='gst_img'></img>
                                        </div>
                                        <div className="label-text">
                                            <div className="title">Expert Support</div>
                                            <div className="subTitle">Access to our team of GST professionals for any assistance you may need</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className="login-container">
                    <a href="https://www.zentaxclinic.com/" target="_blank" > <div className="site-link-btn site-tab">www.zentaxclinic.com</div> </a>
                    <Outlet />
                </div> */}
            </div>
        </>
    )
}