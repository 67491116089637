import React, { createContext, useReducer } from 'react'
import { initialValues } from './initialValues'

const isUsernme = RegExp(/^[A-Za-z0-9_-]+$/)
const isIfsccode = RegExp(/^([A-Z ]{4})+\d+$/)
const isText = RegExp(/^[A-Z ]+$/i)
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
const isPhone = RegExp(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/)
const isZip = RegExp(/^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/)
const isPincode = RegExp(/^[1-9][0-9]{5}$/)
const isNumber = RegExp(/^\d+$/);
const isupiID = RegExp(/^[a-zA-Z0-9._-]+@[\w.-]+$/)
const isaddress = RegExp(/^[\w\s\d.,#-]+$/)
const isotp = RegExp(/^[0-9]{6}$/)
const isGstin = RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/)
const isRefId = RegExp(/^[A-Z]{2}\d{12}[A-Z]$/)
const isCaseId = RegExp(/^[A-Z]\D\d{12}[A-Z0-9]$/)
const isCasestatus = RegExp(/^[A-Za-z ;]+$/)
const number_only = /^[0-9]*$/;
// Applied to all fields


export declare type ValidationSchema = Record<
  string,
  {
    value?: any
    error?: string
    required?: boolean
    validate?: 'ifsccode' | 'text' | 'number' | 'email' | 'phone' | 'zip' | 'pincode' | 'checkbox' | 'select' | 'upiID' | 'address' | 'description' | 'otp' | 'password' | 'username' | 'tradename' | 'gstin' | 'refid' | 'caseid' | 'taxamount' | 'casestatus' | 'fullname' | 'experience' | 'organisation_name' | 'qualification' | 'city'
    minLength?: number
    maxLength?: number
    helperText?: string
  }
>

type ContextProps = {
  formValues: ValidationSchema
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => void
  handleInitial: () => void;
}

export const AppContext = createContext<ContextProps>({

  formValues: initialValues,
  handleChange() { },
  handleInitial() { },
})

interface ProviderProps {
  children: React.ReactNode
}

type State = {
  formValues: ValidationSchema
}

type Action =
  | { type: 'initial' }
  | { type: 'increase' }
  | { type: 'decrease' }
  | { type: 'form-value'; name: string; fieldValue: any }
  | { type: 'form-error'; name: string; error: string }

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'initial':
      return {
        ...state,
        formValues: initialValues
      }
    case 'form-value':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            value: action.fieldValue
          }
        }
      }
    case 'form-error':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            error: action.error
          }
        }
      }

    default:
      return state
  }
}

export function StepsProvider({ children }: ProviderProps) {

  const [{ formValues }, dispatch] = useReducer(reducer, {
    formValues: initialValues
  })
  const handleInitial = () => dispatch({ type: 'initial' })

  // Handle form change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
    const { type, name, value } = event.target;

    const fieldValue = type === 'checkbox' ? checked : value

    dispatch({ type: 'form-value', name, fieldValue })

    const fieldName = initialValues[name]
    if (!fieldName) return

    const { required, validate, minLength, maxLength, helperText } = fieldName

    let error = ''

    if (required && !fieldValue) error = 'This field is required'
    if (minLength && value && value.length < minLength) error = `Minimum ${minLength} characters is required.`
    if (maxLength && value && value.length > maxLength) error = 'Maximum length exceeded!'
    if (validate) {
      switch (validate) {

        case 'ifsccode':
          if (value && !isIfsccode.test(value)) error = helperText || 'First four Digits Uppercase and Balance Digits numbers only.'
          break

        case 'text':
          if (value && !isText.test(value)) error = helperText || 'This field accepts text only.'
          break

        case 'number':
          if (value && !isNumber.test(value)) error = helperText || 'This field accepts numbers only.'
          break

        case 'email':
          if (value && !isEmail.test(value)) error = helperText || 'Kindly enter a valid email address.'
          break

        case 'phone':
          if (value && !isPhone.test(value))
            error = helperText || 'Kindly enter a valid phone number.'
          break

        case 'zip':
          if (value && !isZip.test(value)) error = helperText || 'Kindly enter a valid pincode.'
          break

        case 'pincode':
          if (value && !isPincode.test(value))
            error = helperText || 'Kindly enter a valid pincode.'
          break

        case 'checkbox':
          if (!checked) error = helperText || 'Kindly provide a valid value.'
          break

        case 'select':
          if (!value) error = helperText || 'Kindly select a value.'
          break

        case 'upiID':
          if (value && !isupiID.test(value)) error = helperText || 'Kindly enter a valid UPI id.'
          break

        case 'address':
          if (!value) error = helperText || 'Kindly enter address'
          break

        case 'description':
          if (!value) error = helperText || 'Kindly enter description'
          break

        case 'otp':
          if (value && !isotp.test(value)) error = helperText || 'Invalid OTP (accept 6 digits numbers only)'
          break

        case 'password':
          if (!value) error = helperText || 'Kindly enter password'
          break

        case 'username':
          if (!value) error = helperText || 'Kindly enter username'
          break

        case 'tradename':
          if (!value) error = helperText || 'Kindly enter tradename'
          break

        case 'gstin':
          if (value && !isGstin.test(value)) error = helperText || "Kindly enter a valid GSTIN"
          break

        case 'refid':
          if (value && !isRefId.test(value)) error = helperText || 'Kindly enter a valid reference id'
          break

        case 'caseid':
          if (value && !isCaseId.test(value)) error = helperText || 'Kindly enter a valid Case Id'
          break

        case 'casestatus':
          if (value && !isCasestatus.test(value)) error = helperText || 'Kindly enter a valid Case status'
          break

        case 'taxamount':
          if (value && !isNumber.test(value)) error = helperText || 'This field accepts numbers only.'
          break

        case 'fullname':
          if (!value) error = helperText || "Kindly enter fullname"
          break

        case 'experience':
          if (!value) error = helperText || 'Kindly enter experience'
          break

        case 'organisation_name':
          if (!value) error = helperText || 'Kindly enter organisation name'
          break

        case 'qualification':
          if (!value) error = helperText || 'Kindly enter qualification'
          break

        case 'city':
          if (!value) error = helperText || 'Kindly enter city'
          break

        default:
          break
      }
    }

    dispatch({ type: 'form-error', name, error })
  }

  return (
    <AppContext.Provider value={{ formValues, handleChange, handleInitial }}>
      <div className='mui-step-form'>{children}</div>
    </AppContext.Provider>
  )
}
