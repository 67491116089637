// react 
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
// .........

// scss file
import 'react-quill/dist/quill.snow.css';
import './chat.scss';
// .........

// package components
import { Avatar, Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import { Modal } from 'antd';
// .........

// child components
import { serviceConfig } from '../../../core/services';
// .........

// icons
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import doc_icon from '../../../assets/images/document_icon.svg';
import sendIcon from '../../../assets/images/sendIcon.png';
import closeIcon from '../../../assets/register/close.png';
// .........

export const ChatWindow = (ticket_id: any) => {

    // alerts and modal states
    const [modalOpen, setModalopen] = useState(false);
    // .........

    // loading state
    // .........

    // data states
    const [chatArray, setChateArray] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const [mobwinopen, setMobwinopen] = useState(false)
    const [content, setContent] = useState<string>('');
    const [userData, setUserData] = useState<any>()
    // .........

    // reference states
    const inputFileElement = useRef<any>();
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    // .........

    // Get chat data
    const getChatData = useCallback(() => {
        serviceConfig.get("get_conversations", true, { task: ticket_id.id  }, null).then((data: any) => {
            // this gives an object with dates as keys
            if (!Array.isArray(data)) {
                // Handle the case where data is not an array
                console.error("Data is not an array:", data);
                return;
            }

            const groups = data.reduce((groups: any, chat: any) => {
                const date = new Date(chat.created_at).toLocaleDateString([], { day: 'numeric', month: 'short', year: 'numeric' });
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(chat);
                return groups;
            }, {});

            // Edit: to add it in the array format instead
            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    chat: groups[date]
                };
            });
            setChateArray(groupArrays)
            scrollToBottom();
            console.log(groupArrays, 'grouparray chats')
        }, (err: any) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        getChatData()
        setUserData(JSON.parse(localStorage.getItem('userData') || ''))
    }, [getChatData])

    // .........

    // form value validation function
    const handleselectedFile = (event: any) => {
        let oldFiles = [...attachments]
        Array.from(event.target.files).forEach(fi => {
            oldFiles.push(fi)
        });
        setAttachments(oldFiles)

    };

    const removeFile = (ind: any) => {
        let oldFiles = [...attachments]
        oldFiles.splice(ind, 1)
        setAttachments(oldFiles)
    }
    // .........

    // PostReply send function for chat
    const postReply = () => {
        scrollToBottom();

        let req_body: any;
        if (attachments.length > 0) {
            req_body = new FormData()
            attachments.forEach((file: any, index: any) => {
                req_body.append('file' + (index + 1), file)
            });
            req_body.append('file_count', attachments.length)
            req_body.append('reply', content)
        } else {
            req_body = { reply: content, file_count: 0 }
        }
        serviceConfig.post("task_reply", true, req_body, { task: ticket_id.id }, null).then((data: any) => {
            setContent('')
            setAttachments([])
            getChatData()
            // console.log('postchat')

        }, (err: any) => {
            console.log(err)
        })
    }
    // .........

    // React-quill onchange fucntion
    const handleContentChange = (value: string) => {
        setContent(value);
        console.log(value, 'value content details')
        console.log(content, 'print content details')
    };

    const isEmptyContent = (content: string) => {
        // Remove HTML tags and trim white spaces
        const text = content.replace(/<[^>]*>/g, '').trim();
        // Check if the remaining text is empty or contains only empty lines
        return text.length === 0 || text === '<p><br></p>';
    };
    // .........

    // format the Date and Time of chat window function
    interface Props {
        isoDateTime: string; // Input ISO 8601 date-time string
    }

    const DateTimeFormatter: React.FC<Props> = ({ isoDateTime }) => {
        const formattedTime = new Date(isoDateTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return <span>{formattedTime}</span>;
    };
    // .........

    // attachment options on chat window function
    const openAttachment = (att: any) => {
        let a_el = document.createElement('a')
        a_el.href = att.attachment_url
        a_el.target = '_blank'
        a_el.download = att.name;
        a_el.click()
    }
    // .........

    // Function for scroll to the end of the chat window
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    // .........

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            postReply();
        }
    };

    return (
        <>
            <Modal
                title={"Chat"}
                onCancel={() => { setModalopen(false); setMobwinopen(false); setContent(''); setAttachments([]) }}
                className='chat-popup'
                centered
                style={{ color: "#031742" }}
                open={modalOpen}
                closeIcon={null}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className='mail-window'>
                    <div className='texteditor'>
                        <ReactQuill
                            style={{}}
                            value={content}
                            onChange={handleContentChange}
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'align': [] }],
                                    ['image'],
                                ],
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '0.5rem',
                            width: '100%',
                            flexWrap: 'wrap'
                        }}>
                        {attachments.map(
                            (file: any, index: any) =>
                                <span key={file.name}
                                    style={{
                                        padding: '0.4% 0.7%',
                                        marginLeft: '2.5%',
                                        backgroundColor: '#FCE7D9',
                                        border: '0.5px solid #E96919',
                                        marginRight: '1%',
                                        marginBottom: '1%',
                                        justifyContent: 'space-between',
                                        borderRadius: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#000'
                                    }}>
                                    {file.name}
                                    <img
                                        alt="close-icon"
                                        onClick={() => removeFile(index)}
                                        src={closeIcon}
                                        style={{
                                            marginLeft: '5px',
                                            cursor: 'pointer',
                                            height: '2vh'
                                        }}
                                        height={'10vmin'} />
                                </span>)}
                    </div>
                    <div
                        className='window-footer'>
                        <Button
                            onClick={() => { setContent(''); setAttachments([]) }}
                            className='delete-icon'
                            variant='text' >
                            Cancel
                        </Button>
                        <input
                            ref={inputFileElement}
                            onChange={handleselectedFile}
                            multiple
                            type="file"
                            style={{ display: 'none' }} />
                        <Fab
                            size="small"
                            onClick={() => inputFileElement.current.click()}
                            className='attach-btn' >
                            <AttachFileIcon className='pin-img' />
                        </Fab>
                        <Fab
                            size="small"
                            onClick={() => { postReply(); console.log(content) }}
                            className='send-btn'
                            disabled={!content || isEmptyContent(content)}
                        >
                            <SendIcon className='send-img' />
                        </Fab>
                    </div>
                </div>
            </Modal >
            <div
                className="z-support-container">

                <div className='z-support-body-wrap'>
                    <div className="support-body"
                    >
                        <p
                            className='chat-group-header'
                            style={{
                                textAlign: 'center',
                                marginTop: '5px',
                                color: 'var(--low-contrast-text, #666)',
                                fontFamily: 'Inter', 'fontSize': '1.8vmin',
                                fontWeight: 400
                            }}>
                            Task created by you on :
                            <span
                                style={{
                                    color: 'var(--text-black, #393938)',
                                    fontSize: '2vmin',
                                    fontWeight: 500
                                }}>
                                {new Date(localStorage.getItem('taskCreatedDate') || '').toLocaleDateString([], { day: 'numeric', month: 'short', year: 'numeric' })}
                            </span>
                        </p>
                        <div className="chat-body">
                            {chatArray.length > 0 &&
                                chatArray.map((date_chat: any, ind: any) => {
                                    return <>
                                        <p style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '1.6vmin',
                                            fontWeight: 500,
                                            padding: '6px 32px',
                                            borderRadius: '20px',
                                            background: '#F3F3F9',
                                            width: 'max-content',
                                            margin: 'auto',
                                            marginBottom: '15px'
                                        }}
                                            key={date_chat.date}>
                                            {date_chat.date}</p>
                                        {date_chat.chat.map((chat: any, i: any) => {
                                            return <div
                                                key={date_chat.date + i}
                                                className='chats' >
                                                <div
                                                    className="chat-parent"
                                                    style={chat.category === 1 ? { right: 0, alignItems: 'end' } : { left: 0, float: 'left' }}>
                                                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                                                    <div
                                                        className="chat-child"
                                                        style={chat.category === 1 ?
                                                            {
                                                                right: 0,
                                                                float: 'right',
                                                                marginRight: '3%',
                                                                backgroundColor: '#ED7D31',
                                                                borderRadius: '10px 0 10px 10px',
                                                                color: '#fff',
                                                                marginBottom: '10px'
                                                            } :
                                                            {
                                                                left: 0,
                                                                float: 'left',
                                                                marginLeft: '3%',
                                                                backgroundColor: '#424B70',
                                                                borderRadius: '0 10px 10px 10px',
                                                                color: '#fff',
                                                                marginBottom: '10px'
                                                            }}>
                                                        <span
                                                            style={chat.category === 1 ?
                                                                { paddingRight: '3%' } :
                                                                { paddingLeft: '3%' }}>
                                                            <div className='chat-body-html' style={{ whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: chat.body }} />
                                                            {/* {chat.body} */}
                                                        </span>
                                                        <span
                                                            className='chat-time'
                                                            style={{
                                                                textAlign: 'right',
                                                                fontFamily: 'Inter',
                                                                fontSize: '1vmin'
                                                            }}><DateTimeFormatter isoDateTime={chat.created_at} /></span>
                                                    </div>
                                                    {chat.attachments.length > 0 &&
                                                        chat.attachments.map((att: any) =>
                                                            <span
                                                                onClick={() => openAttachment(att)}
                                                                className='attachment-span'
                                                                style={chat.category === 1 ?
                                                                    {
                                                                        right: 0,
                                                                        float: 'right',
                                                                        marginRight: '4%',
                                                                    } :
                                                                    {
                                                                        left: 0,
                                                                        float: 'left',
                                                                        marginLeft: '4%'
                                                                    }}>
                                                                <img src={doc_icon} alt="doc-icon" />
                                                                {att.name}
                                                            </span>)}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        })}
                                        <div
                                            style={{
                                                position: 'relative',
                                                bottom: '-5%',
                                                height: '1px'
                                            }}
                                            ref={messagesEndRef}>

                                        </div>
                                    </>
                                })}
                            {chatArray.length === 0 &&
                                <p
                                    style={{
                                        color: '#ccc',
                                        fontSize: '3.5vmin',
                                        textAlign: 'center'
                                    }}>
                                    No conversations found!
                                </p>}
                        </div>

                        {/* <div className="chat-input" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='input-box'>
                        <input onKeyDown={handleKeyDown} disabled={loading} value={taskReply} onChange={(ev) => setTaskReply(ev.target.value)} className="input-field" placeholder='Communicate with Zen Tax clinic' type='text'></input>
                        <div className="action-icons">
                            <input ref={inputFileElement} onChange={handleselectedFile} multiple type="file" style={{ display: 'none' }} />
                            <img onClick={() => inputFileElement.current.click()} className='pin-img' src={attachmentIcon} style={{ cursor: 'pointer', width: '2.6vmin' }}></img>
                            <img src={sendIcon} className='send-img' style={{ position: 'relative', left: '35%', cursor: 'pointer', width: '3vmin' }} onClick={() => !loading && postReply()}></img>
                        </div>
                     </div>
                    <div style={{ display: 'flex', marginTop: '0.5rem', width: '100%', flexWrap: 'wrap' }}>{attachments.map((file: any, index: any) => <span key={file.name} style={{ padding: '0.4% 0.7%', marginLeft: '2.5%', backgroundColor: '#FCE7D9', border: '0.5px solid #E96919', marginRight: '1%', marginBottom: '1%', justifyContent: 'space-between', borderRadius: '15px', display: 'flex', alignItems: 'center', color: '#000' }}>{file.name} <img onClick={() => removeFile(index)} src={closeIcon} style={{ marginLeft: '5px', cursor: 'pointer', height: '2vh' }} height={'10vmin'} /></span>)}</div>
                </div> */}
                        {!mobwinopen &&
                            <Fab
                                onClick={() => { setModalopen(true); setMobwinopen(true) }}
                                size="medium"
                                color="secondary"
                                aria-label="add"
                                className='mobile-float-btn'>
                                <img alt="send-icon" src={sendIcon} className='float-icon'></img>
                            </Fab>}
                    </div>

                    <div className='mail-icon-cover'>
                        <div className='avatar-wrap'>
                            <Avatar className='avatar'> {userData ? userData.user_name.charAt(0) : ''}</Avatar>
                        </div>
                        <div className='mail-window'>
                            <div className='texteditor'>
                                <ReactQuill
                                    // style={{color:'#000'}}
                                    value={content}
                                    // onKeyDown={handleKeyDown}
                                    onChange={handleContentChange}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['bold', 'italic', 'underline'],
                                            [{ 'align': [] }],
                                            ['image'],
                                        ],
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: '0.5rem',
                                    width: '100%',
                                    flexWrap: 'wrap'
                                }}>
                                {attachments.map((file: any, index: any) =>
                                    <span
                                        key={file.name}
                                        style={{
                                            padding: '0.4% 0.7%',
                                            marginLeft: '2.5%',
                                            backgroundColor: '#FCE7D9',
                                            border: '0.5px solid #E96919',
                                            marginRight: '1%',
                                            marginBottom: '1%',
                                            justifyContent: 'space-between',
                                            borderRadius: '15px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#000'
                                        }}>
                                        {file.name}
                                        <img
                                            alt="closeicon"
                                            onClick={() => removeFile(index)}
                                            src={closeIcon}
                                            style={{
                                                marginLeft: '5px',
                                                cursor: 'pointer',
                                                height: '2vh'
                                            }}
                                            height={'10vmin'} />
                                    </span>)}
                            </div>
                            <div
                                className='window-footer'>
                                <input
                                    ref={inputFileElement}
                                    onChange={handleselectedFile}
                                    multiple
                                    type="file"
                                    style={{ display: 'none' }} />
                                <Fab
                                    size="small"
                                    onClick={() => inputFileElement.current.click()}
                                    className='attach-btn' >
                                    <AttachFileIcon className='pin-img' />
                                </Fab>
                                <Fab
                                    size="small"
                                    onClick={() => { postReply() }}
                                    className='send-btn'
                                    disabled={!content || isEmptyContent(content)}
                                >
                                    <SendIcon className='send-img' />
                                </Fab>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}