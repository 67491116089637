// react 
import React, { useState } from "react";
import { Link } from 'react-router-dom';
// ..........

// scss file
import './mail-success.scss';
// ..........

// package components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// child components
import mailSent from '../../../assets/register/mail-sent.png';
// ..........

export const MailTemplate = () => {

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // Resend mail function
    const resendMail = () => {
        if (!loading) {
            setLoading(true)
            serviceConfig.get('resend_mail', true, { id: localStorage.getItem('userId') }, null).then((data: any) => {
                console.log("data result", data.response)
                setNotifyType("success");
                setNotifyMessage("Mail sent successfully.");
                setNotifyOpen(true);
                setLoading(false)
            },
                (err: any) => {
                    console.log("res err", err.response);
                    setLoading(false)
                })
        }
    }
    // ..........

    return (
        <>
            <div className="mail-container">
                <div>
                    <img className="icons" src={mailSent} alt='mail_icon'></img>
                </div>
                <h4 className='terminal-window-mainline heading'>Check your mail</h4>
                <p className="terminal-window-subline">Please check your mailbox including Spam and Bin folder for setting
                    password and accessing the Portal</p>
                <div className="col-sm-12 input-group">
                    <button className="register teminal-primary-btn" onClick={resendMail}>{loading ? 'Loading...' : 'Resend Mail'}</button>
                </div>
                <div className="col-sm-12 login-link" style={{ textAlign: 'center', paddingBottom: '3%' }}>
                    <Link className="terminal-link" to='../'>Back to Login</Link>
                </div>
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}