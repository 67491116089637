// react 
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
// ..........

// scss file
import './forgot-password.scss';
// ..........

// package components
import { FormHelperText, OutlinedInput, ThemeProvider, createTheme } from '@mui/material';
// ..........

// child components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// icons
import mailIcon from '../../../assets/register/mail.png';
// ..........

export const ForgotPassword = () => {

    // form values states
    const [email, setEmail] = useState('')
    const [err_email, setErrEmail] = useState('')
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // reference states
    const navigate = useNavigate();
    // ..........

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                forgotPassword()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email]);

    // form value validation function
    const validateEmail = (email: any) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    const handleEmailChange = (e: any) => {
        const newval = e.target.value;
        setEmail(newval);
        if (newval !== '') {
            setErrEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        } else {
            setErrEmail("*Please enter registered mail id")
        }
    }
    // ..........

    // confirm usermail function
    const checkUserMail = (callback: any) => {
        let req_body = {
            "mail": email.toLowerCase()
        }
        console.log(req_body, 'for email')
        serviceConfig.get('check_mail', true, req_body, null).then((data: any) => {
            callback()
        },
            (err: any) => {
                setLoading(false)
                setErrEmail("*Given mail is not registered with us");
                console.log("res err", err.response);
            })

    }
    // ..........

    // Forget password submission function
    const forgotPassword = (e?: any) => {
        if (!email) {
            setErrEmail('*Please enter registered mail id')
        }
        else if (!loading) {
            setLoading(true)
            checkUserMail(() => {
                let query = { email: email.toLowerCase() }
                serviceConfig.post('forget_password', true, query, null).then((data: any) => {
                    setEmail('')
                    setLoading(false)
                    navigate('/mail-success')
                },
                    (err: any) => {
                        console.log("res err", err.response);
                        setNotifyType("error");
                        setNotifyMessage(err.message);
                        setNotifyOpen(true);
                        setLoading(false)
                    })
            })
        }
    }
    // ..........

    // custom theming function
    const inputtheme = createTheme({
        palette: {
            primary: {
                main: '#9a9999',
            }
        },
    });
    // ..........

    return (
        <>
            <div className="container-fgt-pwd">
                <h4 className="terminal-window-mainline">Forgot Password?</h4>
                <p className="terminal-window-subline line-margin">No worries, we’ll send you reset instructions</p>
                <div className="fields">
                    <div className="row">
                        {/* <form onSubmit={forgotPassword}> */}
                        <div className="col-sm-12 input-group">
                            <label className="input-label">Email</label>
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='inputfield'
                                    id="outlined-adornment-weight"
                                    placeholder='Enter your registered mail id'
                                    value={email}
                                    error={!!err_email}
                                    onChange={handleEmailChange}
                                    startAdornment={<img className="input-icon" src={mailIcon} alt="email_img" />}
                                    aria-describedby="outlined-weight-helper-text"
                                />
                                <FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} id='error-line'>{err_email}</FormHelperText>
                            </ThemeProvider>
                            {/* <div className="input-box" >
                                <img className="input-icon" src={mailIcon} alt='email_img'></img>
                                <input className='inputfield' value={email} onBlur={handleEmailChange} onChange={handleEmailChange} type="text" placeholder="Enter your registered mail id"></input>
                                <span className='input-error-line'>{err_email}</span>
                            </div> */}
                        </div>

                        <div className={err_email ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_email ? '0.5rem' : '' }} >
                            <button type="submit" className="teminal-primary-btn" onClick={forgotPassword}>{loading ? 'Loading...' : 'Reset Password'}</button>
                        </div>
                        {/* </form> */}
                        <div className="col-sm-12 forgot-link" style={{ marginTop: '4%' }}>
                            <Link className="terminal-link" to='../'>Back to Login</Link>
                        </div>

                    </div>
                </div>
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}