// react 
import { Outlet } from 'react-router-dom';
// ..........

// scss file
import './landing.scss'
// ..........

export const Landing = () => {
    return (
        <>
            <div className="landing">
                <Outlet />
            </div>
        </>
    )
}