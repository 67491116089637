// react 
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
// ..........

// scss file
import './index.scss';
// ..........

// child components
import App from './app/App';
import { StateProvider } from './app/Statecontext';
import { StepsProvider } from './app/core/utils/Context';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// ..........

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StateProvider>
    <React.StrictMode>
      <HashRouter>
        <StepsProvider>
          <App />
        </StepsProvider>
      </HashRouter>
    </React.StrictMode>
  </StateProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

reportWebVitals();
