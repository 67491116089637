// react 
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './phpartners.scss';
// .........

// package components
import { DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Button, Radio, RadioChangeEvent, Select } from 'antd';
// .........

// child components
import { useGlobalState } from '../../Statecontext';
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
// .........

// icons
import CloseIcon from '@mui/icons-material/Close';
import filterIcon from '../../assets/images/filter.svg';
// .........

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const Phpartners = () => {

    // form values states
    const [usersList, setUsersList] = useState<any>([]);
    const [ph_partner_id, setPh_partner_id] = useState<any>('');
    const [task_id, setTask_id] = useState<any>('');
    const [task, setTasks] = useState('Tasks');
    const [tasklist, setTasklist] = useState<any>([])
    const [selected, setSelected] = useState('Select Action')
    // .........

    // data states

    // .........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [assignpopup, setAssignpopup] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .........

    // loading state
    const [loading, setLoading] = useState(false)
    // .........

    // limit & filter states
    const { filterQuery, page, totalpages, setTotalpages, rowsPerPage, userdata, setUserdata } = useGlobalState();
    // .........

    // reference states
    const navigate = useNavigate()
    // .........

    // view data function

    // ............

    // data get function
    const loadUser = useCallback((search_data?: any) => {

        setLoading(true)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }

        query['approval_status'] = ['approved']

        if (userdata && userdata.is_cf === true) {
            query['cf_code'] = userdata.cf_code
        }

        serviceConfig.get("ph_register", true, query, null).then((data: any) => {
            setTotalpages(data.count)
            setUsersList(data.results || data.data)
            setLoading(false)
        }, (err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filterQuery, userdata])

    useEffect(() => {
        loadUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterQuery])
    // .........

    // radio selection 
    const handlechange = (value: string) => {
        setSelected(value)
    };
    const onChange = (e: RadioChangeEvent) => {
        setTasks(e.target.value);
        setSelected(e.target.value);
    };
    // ...........

    // assignfuction
    const taskgetcall = () => {
        setLoading(true)
        serviceConfig.get("ticket_list", true, null, null).then((data: any) => {
            setTasklist(data.data)
            console.log(data.data, 'solution dashborad data for taskid')
            setTotalpages(data.data.length)
            setLoading(false)
        }, (err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }

    const handleassign = () => {
        setAssignpopup(true)
        taskgetcall()
    }
    // ..........

    //  function Ph assign 
    const Assignpartner = () => {
        setLoading(true)
        let body: any = {
            task_id: task_id,
            ph_partner_id: ph_partner_id
        }

        serviceConfig.post('profile_user', true, body, null).then((res: any) => {
            console.log("response", res);

            setLoading(false)
        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage(err)
                setNotifyOpen(true)
                setLoading(false)
            }
        )
    }
    // ..........

    // table columns and filter requirement props
    const columns: any = [
        {
            title: 'S No.',
            dataIndex: '',
            render(value: any, record: any, index: any) {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'PH Partner Name',
            dataIndex: 'full_name',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Organization Name',
            dataIndex: 'org_name',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Pincode',
            dataIndex: 'pin_code',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'PH office',
            dataIndex: 'city',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Depth of GST Practice',
            dataIndex: 'depth_gst',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
    ];
    // .........

    // button details props
    const buttondetails: any = [
        {
            type: 'assign',
            name: 'Assign to Zentax Clinic Task',
            theme: 'theme2',
            conditions: [],
            function: handleassign,
        }
    ]
    // .........

    return (
        <>
            <BootstrapDialog
                onClose={() => { setAssignpopup(false) }}
                aria-labelledby="customized-dialog-title"
                open={assignpopup}
                id='assign_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    Tasks
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setAssignpopup(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='dialog-content' style={{ marginTop: '0%' }}>
                    <div className='assign-form-wrap'>
                        <div className="assign-select-container">
                            <Select
                                className="dropdown-btn"
                                onChange={handlechange}
                                value={task}
                                dropdownRender={menu => {

                                    return (
                                        <div>

                                            <Radio.Group onChange={onChange} value={task} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Radio value={'No action required'}>No action required</Radio>
                                                <Radio value={'Action to be taken'}>Action to be taken</Radio>
                                                <Radio value={'Action taken'}>Action taken</Radio>
                                            </Radio.Group>

                                        </div>
                                    )
                                }}
                                options={[
                                    { value: 'No action required' },
                                    { value: 'Action to be taken', },
                                    { value: 'Action taken' },
                                ]}
                            />
                        </div>
                        <Button
                            id="z-common-gst-solution-button"
                            className='theme2'
                        >
                            Assign
                        </Button>
                    </div>
                </DialogContent>
            </BootstrapDialog >
            <div className='users-approval-container'>
                <ZTable2 label={'PH Partners'} button={buttondetails} columns={columns} expand={false} hideSelect={false} loading={loading} tabledata={usersList} totalpages={totalpages} />
            </div>
        </>
    )
}