import { ValidationSchema } from './Context'

export const initialValues: ValidationSchema = {
  user_name: {
    value: '',
    error: '',
    required: true,
    validate: 'username',
  },
  password: {
    value: '',
    error: '',
    required: true,
    validate: 'password',
  },
  typeOfSolution: {
    value: 'Reply to Notice',
    error: '',
    required: false,
    validate: 'select'
  },
  ticDescription: {
    value: '',
    error: '',
    required: false,
    validate: 'description'
  },
  ticContactNumber: {
    value: '',
    error: '',
    required: true,
    validate: 'phone'
  },
  otp: {
    value: '',
    error: '',
    required: true,
    validate: 'otp'
  },
  state: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
  },
  trade_name: {
    value: '',
    error: '',
    required: true,
    validate: 'tradename',
  },
  gstin: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
  },
  gstin_input: {
    value: '',
    error: '',
    validate: 'gstin',
    required: true,
  },
  refid: {
    value: '',
    error: '',
    validate: 'refid',
    required: false,
  },
  caseid: {
    value: '',
    error: '',
    validate: 'caseid',
    required: false,
  },
  casestatus: {
    value: 'Action to be taken',
    error: '',
    validate: 'select',
    required: false,
  },
  casetype: {
    value: '',
    error: '',
    validate: 'select',
    required: false,
  },
  section: {
    value: '',
    error: '',
    validate: 'number',
    required: false,
    minLength: 2,
    maxLength: 3,
  },
  taxamount: {
    value: '',
    error: '',
    validate: 'number',
    required: true,
  },
  tax_period: {
    value: '',
    error: '',
    validate: 'select',
    required: true,
  },
  fullname: {
    value: '',
    error: '',
    required: true,
    validate: 'fullname',
  },
  email: {
    value: '',
    error: '',
    required: true,
    validate: 'email',
  },
  experience: {
    value: '',
    error: '',
    required: true,
    validate: 'experience',
  },
  address: {
    value: '',
    error: '',
    required: true,
    validate: 'address',
  },
  organisation_name: {
    value: '',
    error: '',
    required: true,
    validate: 'organisation_name',
  },
  qualification: {
    value: '',
    error: '',
    required: true,
    validate: 'qualification',
  },
  team_size: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
  },
  pin_code: {
    value: '',
    error: '',
    required: true,
    validate: 'pincode',
  },
  city: {
    value: '',
    error: '',
    required: true,
    validate: 'city',
  },
  alt_contactnumber: {
    value: '',
    error: '',
    required: true,
    validate: 'phone',
  },
  remarks: {
    value: '',
    error: '',
    required: true,
  }
}