// react 
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// ..........

// scss file
import './pwd-success.scss';
// ..........

// child components
import success from "../../../assets/register/success.png";
// ..........

export const PasswordSuccess = () => {

    // reference states
    const navigate = useNavigate();
    // ..........

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                navigate('/login')
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <>
            <div className="pwd-success-container">
                <div  >
                    <img className="icons" src={success} alt='suc_icon'></img>
                </div>
                <h4 className='terminal-window-mainline pwd-line'>Password changed</h4>
                <p className="terminal-window-subline">Your password has been reset successfully</p>
                <div className="col-sm-12 input-group">
                    <button className="teminal-primary-btn register" onClick={() => navigate('/login')}>Login</button>
                </div>
            </div>
        </>
    )
}