// react 
import React from 'react';
// ..........

// scss file
import './App.scss';
// ..........

// child components
import Routes from './core/routes';
// ..........

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
