// react 
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './availgst.scss';
import { Button } from 'antd';
// ..........

// package components
import YouTube from 'react-youtube';
// ..........

// child components
// ..........
export const Availgst = () => {

    // form values states
    const [currenttab, setCurrentab] = useState('Solo Proprietor');
    // ......

    // reference states
    const playerRef = useRef<any>(null);
    // ......

    // header tab details
    const avail_tabs = [
        {
            name: 'Solo Proprietor',
        },
        {
            name: 'Corporate',
        },
    ]
    // ......

    // web page config
    const pageUrl = "https://app.opencapital.co.in/en/onboarding/register?utm_source=zentaxclinic&utm_campaign=zentax_partner&ref=zentaxclinic";
    // ......

    return (
        <div id='avail-gst-dashboard'>
            <div className='avail-menu-container'>
                {avail_tabs.map((button: any) => (
                    <Button onClick={() => setCurrentab(button.name)} className={currenttab === button.name ? 'menu_btn menu_btn_active' : 'menu_btn'}>{button.name}</Button>
                ))}
            </div>
            <div className='avail-content-container'>
                {
                    currenttab === 'Solo Proprietor' &&
                    <div className='solo-poprietor-cover'>
                        <iframe
                            src={pageUrl}
                            width={'95%'}
                            height={'95%'}
                            frameBorder="0"
                            title="Web Page"
                        ></iframe>
                    </div>
                }
            </div>
        </div>
    )
}